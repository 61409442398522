import './App.css';
import Project from './components/Project';
import ErrorBoundary from './components/ErrorBoundary';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import End from './components/End';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';


const client = new ApolloClient({
  uri: "https://leetcode.com/graphql",
  cache: new InMemoryCache(),
});
function App() {
  return (
    <div>
    <Navbar/>
    <ApolloProvider client={client}>
      <Home />
    </ApolloProvider>
    <About/>
    <ErrorBoundary>
      <Project />
    </ErrorBoundary>
    <Contact/>
    <End/>
    </div>
  );
}

export default App;
