import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import '../style/home.css';
import profilepic from '../img/adi.png';
import { BiLogoGmail } from "react-icons/bi";
import { FaLinkedinIn, FaGithub, FaHackerrank } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [leetcodeData, setLeetcodeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLeetcodeData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://portfolio-backend-inky-theta.vercel.app/leetcode", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
            {
              matchedUser(username: "aditya_0102") {
                username
                profile {
                  ranking
                  userAvatar
                  realName
                }
                submitStatsGlobal {
                  acSubmissionNum {
                    difficulty
                    count
                  }
                }
              }
            }
          `,
        }),
      });
      const data = await response.json();
      setLeetcodeData(data.data.matchedUser);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchLeetcodeData();
    }
  }, [isOpen]);

  return (
    <div id='Home'>
      <div className="pic">
        <img src={profilepic} alt="Profile" />
      </div>
      <div className="intro">
        <p id='intro1'>Hey, I am</p>
        <p id='intro2'>Aditya Gupta</p>
        <p id='intro3'>
          A <span>Full Stack Developer</span> dedicated to crafting comprehensive and seamless digital solutions, leveraging both frontend and backend expertise to drive the success of web projects from end to end.
        </p>

        <div id="explore-button">
          <a href="https://drive.google.com/file/d/1uKf8zd8uBFomR4y-VP4qlTOmkrbCNgUu/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            <button id="explore1">Resume</button>
          </a>
          <Link to="About" smooth={true} duration={500}>
            <button id="explore2">See More</button>
          </Link>
        </div>

        <div id="dsa-sec">
          <button className="dsa-btn leetcode" onClick={() => setIsOpen(true)}>
            <SiLeetcode />
          </button>
          <a href="https://www.hackerrank.com/profile/adityagupta9137" target="_blank" rel="noopener noreferrer">
            <button className="dsa-btn hackerrank"><FaHackerrank /></button>
          </a>
        </div>

        <div className="icon">
          <a href="https://www.linkedin.com/in/aditya-gupta-5a752b234" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="mailto: adityagupta9137@gmail.com"><BiLogoGmail /></a>
          <a href="https://github.com/Adityagupta9" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
        </div>

        {/* LeetCode Modal */}
  {/* LeetCode Modal */}
{isOpen && (
  <div className="modal-overlay" onClick={() => setIsOpen(false)}>
    <div className="leetcode-modal" onClick={(e) => e.stopPropagation()}>
      <SiLeetcode className="leetcode-icon-top" /> {/* LeetCode Icon */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error fetching data: {error}</p>
      ) : leetcodeData ? (
        <div className="leetcode-panel">
          <img src={leetcodeData.profile.userAvatar} alt="Profile" className="leetcode-avatar" />
          <h2 className="leetcode-username">{leetcodeData.username} ({leetcodeData.profile.realName})</h2>
          <p className="leetcode-rank"><strong>Rank:</strong> {leetcodeData.profile.ranking}</p>
          <p className="leetcode-solved">
            <strong>Problems Solved:</strong> {leetcodeData.submitStatsGlobal.acSubmissionNum.find(d => d.difficulty === "All")?.count || 0}
          </p>
          <div className="leetcode-buttons">
            <a href="https://leetcode.com/aditya_0102" target="_blank" rel="noopener noreferrer">
              <button className="leetcode-visit">Visit Profile</button>
            </a>
            <button className="leetcode-close" onClick={() => setIsOpen(false)}>Close</button>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  </div>
)}

      </div>
    </div>
  );
}

export default Home;
